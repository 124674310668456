<template>
  <div class="politica_privac_v2cont">
    <div class="ancho_acotado">
      <div class="">
        {{ t("app.politica_privac_v2.p_1_a") }}&nbsp;
        <span class="politica_privac_v2bold">"Girofacil"</span>)&nbsp;
        {{ t("app.politica_privac_v2.p_1_b") }}
      </div>
      <div class="politica_privac_v2separador">
        {{ t("app.politica_privac_v2.p_2_1") }}&nbsp;"
        <span class="politica_privac_v2bold"
          >{{ t("app.politica_privac_v2.p_2_2") }}"</span
        >&nbsp; {{ t("app.politica_privac_v2.p_2_3") }}&nbsp;
        <span class="politica_privac_v2bold"
          >"{{ t("app.politica_privac_v2.p_2_4") }}"</span
        >)&nbsp; {{ t("app.politica_privac_v2.p_2_5") }}&nbsp;
        <span class="politica_privac_v2bold">"Web"</span>&nbsp;
        {{ t("app.politica_privac_v2.p_2_3") }}&nbsp;
        <span class="politica_privac_v2bold">"Website")</span>&nbsp;
        {{ t("app.politica_privac_v2.p_2_6") }}&nbsp;
        <span class="politica_privac_v2bold">{{
          t("app.politica_privac_v2.p_2_7")
        }}</span
        >&nbsp; {{ t("app.politica_privac_v2.p_2_3") }}&nbsp;
        <span class="politica_privac_v2bold">App,</span>&nbsp;
        {{ t("app.politica_privac_v2.p_2_8") }}&nbsp;
        <span class="politica_privac_v2bold"
          >"{{ t("app.politica_privac_v2.p_2_9") }}")</span
        >
      </div>
      <div class="politica_privac_v2separador">
        {{ t("app.politica_privac_v2.p_3_1") }}&nbsp;
        <span class="politica_privac_v2bold"
          >"{{ t("app.politica_privac_v2.p_3_2") }}"</span
        >&nbsp; {{ t("app.politica_privac_v2.p_3_3") }}&nbsp;
        <span class="politica_privac_v2bold"
          >"{{ t("app.politica_privac_v2.p_3_4") }}"</span
        >&nbsp;
        {{ t("app.politica_privac_v2.p_3_5") }}
      </div>

      <div class="politica_privac_v2separador">
        {{ t("app.politica_privac_v2.p_4_1") }}
        <span class="politica_privac_v2bold"
          >"{{ t("app.politica_privac_v2.p_4_2") }}"</span
        >&nbsp;
        {{ t("app.politica_privac_v2.p_4_3") }}
      </div>

      <div class="politica_privac_v2separador">
        {{ t("app.politica_privac_v2.p_5_1") }},&nbsp;
        <span class="politica_privac_v2bold"
          >"{{ t("app.politica_privac_v2.p_5_2") }}"</span
        >,&nbsp; {{ t("app.politica_privac_v2.p_5_3") }},&nbsp;
        <span class="politica_privac_v2bold"
          >"{{ t("app.politica_privac_v2.p_5_4") }}"</span
        >).&nbsp;
        {{ t("app.politica_privac_v2.p_5_5") }}
      </div>

      <div class="politica_privac_v2separador">
        <span class="politica_privac_v2bold"
          >1.-&nbsp;{{ t("app.politica_privac_v2.1_tit") }}</span
        >
      </div>

      <div class="politica_privac_v2separador">
        {{ t("app.politica_privac_v2.1_p_1") }}
      </div>

      <div class="politica_privac_v2separador">
        {{ t("app.politica_privac_v2.1_p_2") }}
      </div>
      <div class="politica_privac_v2separador">
        {{ t("app.politica_privac_v2.1_p_3") }}
      </div>
      <div class="politica_privac_v2separador">
        {{ t("app.politica_privac_v2.1_p_4") }}
      </div>
      <div class="politica_privac_v2separador">
        {{ t("app.politica_privac_v2.1_p_5") }}
      </div>
      <div class="politica_privac_v2separador">
        {{ t("app.politica_privac_v2.1_p_6") }}
      </div>
      <div class="politica_privac_v2separador">
        {{ t("app.politica_privac_v2.1_p_7") }}
      </div>
      <div class="politica_privac_v2separador">
        {{ t("app.politica_privac_v2.1_p_8") }}
      </div>
      <div class="politica_privac_v2separador">
        {{ t("app.politica_privac_v2.1_p_9") }}
      </div>
      <div class="politica_privac_v2separador">
        {{ t("app.politica_privac_v2.1_p_10") }}
      </div>
      <div class="politica_privac_v2separador">
        {{ t("app.politica_privac_v2.1_p_11") }}
      </div>
      <div class="politica_privac_v2separador">
        {{ t("app.politica_privac_v2.1_p_12") }}
      </div>

      <div class="politica_privac_v2separador">
        <span class="politica_privac_v2bold"
          >3.-&nbsp;{{ t("app.politica_privac_v2.3_tit") }}</span
        >
      </div>
      <div class="politica_privac_v2separador">
        {{ t("app.politica_privac_v2.3_p_1") }}
      </div>

      <div class="politica_privac_v2separador">
        <span class="politica_privac_v2bold"
          >4.-&nbsp;{{ t("app.politica_privac_v2.4_tit") }}</span
        >
      </div>
      <div class="politica_privac_v2separador">
        {{ t("app.politica_privac_v2.4_p_1") }}
      </div>
      <div class="politica_privac_v2separador">
        {{ t("app.politica_privac_v2.4_p_2") }}
      </div>

      <div class="politica_privac_v2separador">
        <span class="politica_privac_v2bold"
          >5.-&nbsp;{{ t("app.politica_privac_v2.5_tit") }}</span
        >
      </div>
      <div class="politica_privac_v2separador">
        {{ t("app.politica_privac_v2.5_p_1") }}
      </div>
      <div class="politica_privac_v2separador">
        {{ t("app.politica_privac_v2.5_p_2") }}
      </div>
      <div class="politica_privac_v2separador">
        {{ t("app.politica_privac_v2.5_p_3") }}
      </div>
      <div class="politica_privac_v2separador">
        {{ t("app.politica_privac_v2.5_p_4") }}
      </div>
      <div class="politica_privac_v2separador">
        {{ t("app.politica_privac_v2.5_p_5") }}
      </div>
      <div class="politica_privac_v2separador">
        {{ t("app.politica_privac_v2.5_p_6") }}
      </div>
      <div class="politica_privac_v2separador">
        {{ t("app.politica_privac_v2.5_p_7") }}
      </div>
      <div class="politica_privac_v2separador">
        {{ t("app.politica_privac_v2.5_p_8") }}
      </div>
      <div class="politica_privac_v2separador">
        {{ t("app.politica_privac_v2.5_p_9") }}
      </div>
      <div class="politica_privac_v2separador">
        {{ t("app.politica_privac_v2.5_p_10") }}
      </div>
      <div class="politica_privac_v2separador">
        {{ t("app.politica_privac_v2.5_p_11") }}
      </div>

      <div class="politica_privac_v2separador">
        <span class="politica_privac_v2bold"
          >6.-&nbsp;{{ t("app.politica_privac_v2.6_tit") }}</span
        >
      </div>
      <div class="politica_privac_v2separador">
        {{ t("app.politica_privac_v2.6_p_1") }}
      </div>
      <div class="politica_privac_v2separador">
        {{ t("app.politica_privac_v2.6_p_2") }}
      </div>
      <div class="politica_privac_v2separador">
        {{ t("app.politica_privac_v2.6_p_3") }}
      </div>

      <div class="politica_privac_v2separador">
        <span class="politica_privac_v2bold"
          >7.-&nbsp;{{ t("app.politica_privac_v2.7_tit") }}</span
        >
      </div>
      <div class="politica_privac_v2separador">
        {{ t("app.politica_privac_v2.7_p_1") }}
      </div>

      <div class="politica_privac_v2separador">
        <span class="politica_privac_v2bold"
          >8.-&nbsp;{{ t("app.politica_privac_v2.8_tit") }}</span
        >
      </div>
      <div class="politica_privac_v2separador">
        {{ t("app.politica_privac_v2.8_p_1") }}
      </div>

      <div class="politica_privac_v2separador">
        <span class="politica_privac_v2bold"
          >9.-&nbsp;{{ t("app.politica_privac_v2.9_tit") }}</span
        >
      </div>
      <div class="politica_privac_v2separador">
        {{ t("app.politica_privac_v2.9_p_1") }}
      </div>
      <div class="politica_privac_v2separador">
        {{ t("app.politica_privac_v2.9_p_2") }}
      </div>
      <div class="politica_privac_v2separador">
        {{ t("app.politica_privac_v2.9_p_3") }}
      </div>
      <div class="politica_privac_v2separador">
        {{ t("app.politica_privac_v2.9_p_4") }}
      </div>
      <div class="politica_privac_v2separador">
        {{ t("app.politica_privac_v2.9_p_5") }}
      </div>
      <div class="politica_privac_v2separador">
        {{ t("app.politica_privac_v2.9_p_6") }}
      </div>
      <div class="politica_privac_v2separador">
        {{ t("app.politica_privac_v2.9_p_7") }}
      </div>

      <div class="politica_privac_v2separador">
        <span class="politica_privac_v2bold"
          >10.-&nbsp;{{ t("app.politica_privac_v2.10_tit") }}</span
        >
      </div>
      <div class="politica_privac_v2separador">
        {{ t("app.politica_privac_v2.10_p_1") }}
      </div>

      <div class="politica_privac_v2separador">
        <span class="politica_privac_v2bold"
          >11.-&nbsp;{{ t("app.politica_privac_v2.11_tit") }}</span
        >
      </div>
      <div class="politica_privac_v2separador">
        {{ t("app.politica_privac_v2.11_p_1") }}
      </div>
      <div class="politica_privac_v2separador">
        {{ t("app.politica_privac_v2.11_p_2") }}
      </div>
      <div class="politica_privac_v2separador">
        {{ t("app.politica_privac_v2.11_p_3") }}
      </div>
      <div class="politica_privac_v2separador">
        {{ t("app.politica_privac_v2.11_p_4") }}
      </div>
      <div class="politica_privac_v2separador">
        {{ t("app.politica_privac_v2.11_p_5") }}&nbsp;denuncias@girofacil.com{{ t("app.politica_privac_v2.11_p_5_") }}&nbsp;sac@girofacil.com.
      </div>

      <div class="politica_privac_v2separador">
        <span class="politica_privac_v2bold"
          >12.-&nbsp;{{ t("app.politica_privac_v2.12_tit") }}</span
        >
      </div>
      <div class="politica_privac_v2separador">
        {{ t("app.politica_privac_v2.12_p_1") }}
      </div>
      <div class="politica_privac_v2separador">
        {{ t("app.politica_privac_v2.12_p_2") }}
      </div>
      <div class="politica_privac_v2separador">
        {{ t("app.politica_privac_v2.12_p_3") }}&nbsp;sac@girofacil.com.
      </div>

      <div class="politica_privac_v2separador">
        <span class="politica_privac_v2bold"
          >13.-&nbsp;{{ t("app.politica_privac_v2.13_tit") }}</span
        >
      </div>
      <div class="politica_privac_v2separador">
        {{ t("app.politica_privac_v2.13_p_1") }}
      </div>
      <div class="politica_privac_v2separador">
        {{ t("app.politica_privac_v2.13_p_2") }}
      </div>
      <div class="politica_privac_v2separador">
        {{ t("app.politica_privac_v2.13_p_3") }}
      </div>
      <div class="politica_privac_v2separador">
        {{ t("app.politica_privac_v2.13_p_4") }}
      </div>
      <div class="politica_privac_v2separador">
        {{ t("app.politica_privac_v2.13_p_5") }}&nbsp;denuncias@girofacil.com{{ t("app.politica_privac_v2.13_p_5_") }}&nbsp;sac@girofacil.com.
      </div>
      <div class="politica_privac_v2separador">
        {{ t("app.politica_privac_v2.13_p_6") }}
      </div>
      <div class="politica_privac_v2separador">
        {{ t("app.politica_privac_v2.13_p_7") }}
      </div>

      <div class="politica_privac_v2separador">
        <span class="politica_privac_v2bold"
          >14.-&nbsp;{{ t("app.politica_privac_v2.14_tit") }}</span
        >
      </div>
      <div class="politica_privac_v2separador">
        {{ t("app.politica_privac_v2.14_p_1") }}
      </div>

      <div class="politica_privac_v2separador">
        <span class="politica_privac_v2bold"
          >15.-&nbsp;{{ t("app.politica_privac_v2.15_tit") }}</span
        >
      </div>
      <div class="politica_privac_v2separador">
        {{ t("app.politica_privac_v2.15_p_1") }}
      </div>
      <div class="politica_privac_v2separador">
        {{ t("app.politica_privac_v2.15_p_2") }}&nbsp;sac@girofacil.com.
      </div>

      <div class="politica_privac_v2separador">
        <span class="politica_privac_v2bold"
          >16.-&nbsp;{{ t("app.politica_privac_v2.16_tit") }}</span
        >
      </div>
      <div class="politica_privac_v2separador">
        {{ t("app.politica_privac_v2.16_p_1") }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { useI18n } from "vue-i18n";

export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },
  computed: {
    ...mapState(["montos_pais_docs"]),
  },
  methods: {
    ...mapActions([""]),

    format_number(numerito) {
      if (numerito && numerito != "") {
        return numerito.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
      } else {
        return numerito;
      }
    },
  },
};
</script>

<style>
.politica_privac_v2cont {
  padding: 1rem 0 1rem 0;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #383838;
}
.politica_privac_v2cont_wide {
  padding-left: 1rem;
  padding-right: 1rem;
}
.politica_privac_v2bold {
  font-weight: 500;
}
.politica_privac_v2separador {
  padding-top: 1rem;
}
.faq__table1 {
  display: flex;
  justify-content: center;

  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #383838;
}
.faq__table1 th {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  color: #383838;
  width: 20%;
  max-width: 20%;
}
</style>